package markdixon.name.frontend

import japgolly.scalajs.react._
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.document
import org.scalajs.dom.html.Div

import scala.scalajs.js.Date

object LogoutComponent {

  val Component = ScalaComponent.builder[Props]("LogoutComponent")
    .renderBackend[LogoutComponentOps]
    .componentDidMount($ => Callback.apply(clearCookies()))
    .build

  final case class Props() {
    @inline def render: VdomElement = Component(this)
  }

  private def clearCookies(): Unit = {
    document.cookie.split(";").foreach(c => {
      document.cookie = c
        .replaceAll("^ +", "")
        .replaceAll("=.*", "=;expires=" + new Date().toUTCString() + ";path=/;SameSite=Lax")
    })
  }

  class LogoutComponentOps($: BackendScope[Props, Unit]) {

    def render(): VdomTagOf[Div] = {
      <.div(
        <.h1("Logout"),
        <.p("You are now logged out")
      )
    }
  }

}
