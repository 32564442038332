package markdixon.name.frontend

import io.circe.Decoder
import io.circe.generic.auto._
import japgolly.scalajs.react
import japgolly.scalajs.react.component.Scala.Unmounted
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import markdixon.name.{Comment, Commentable, Gallery, Image}
import markdixon.name.frontend.BackendApi.Error

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue
import scala.scalajs.js.URIUtils

class CommentComponentFactory[T <: Commentable](implicit decoder: Decoder[T]) {

  val component = ScalaComponent.builder[CommentProps]("Comments")
    .initialStateFromProps(p => CommentState(p.comments, ""))
    .backend(new CommentComponentOps[T](_))
    .renderBackend
    .build
}

object CommentComponentFactory {

  def apply(galleryId: String, imageIndex: Int, comments: Seq[Comment]): Unmounted[CommentProps, CommentState, CommentComponentOps[Image]] = {
    val pathPrefix = s"${URIUtils.encodeURIComponent(galleryId)}/image/$imageIndex"
    new CommentComponentFactory[Image]().component(CommentProps(pathPrefix, comments))
  }

  def apply(galleryId: String, comments: Seq[Comment]): Unmounted[CommentProps, CommentState, CommentComponentOps[Gallery]] = {
    val pathPrefix = s"${URIUtils.encodeURIComponent(galleryId)}"
    new CommentComponentFactory[Gallery]().component(CommentProps(pathPrefix, comments))
  }

}

case class CommentProps(pathPrefix: String, comments: Seq[Comment])
case class CommentState(comments: Seq[Comment], commentText: String)

class CommentComponentOps[T <: Commentable]($: BackendScope[CommentProps, CommentState])(implicit d: Decoder[T]) {

  private val commentInputState = $.zoomState(_.commentText)(x => _.copy(commentText = x))

  def render(props: CommentProps, s: CommentState) = {
    <.div(
      <.ul(^.cls := "list-group",
        s.comments.map(c =>
          <.li(^.cls := "list-group-item",
            <.div(^.cls := "d-flex w-100 justify-content-between",
              <.span(c.text, " - ", <.span( ^.cls := "small", c.firstName, " ", c.lastName)),
              <.button(^.`type` := "button", ^.cls := "btn btn-secondary delete", ^.aria.label := "Delete", ^.onClick ==> deleteComment(props, c.id),
                <.small(^.aria.hidden := true, ^.dangerouslySetInnerHtml := "&times;")
              )
            )
          )).toTagMod,
        <.li(^.cls := "list-group-item",
          <.input(^.id := "commment", ^.value := s.commentText, ^.onChange ==> updateComment),
          <.button(^.onClick ==> submitComment(props), ^.cls := "btn btn-primary", "Comment")
        )
      )
    )
  }

  def updateComment(event: ReactEventFromInput): Callback = {
    commentInputState.setState(event.target.value)
  }

  def submitComment(props: CommentProps)(event: ReactEventFromInput): react.Callback = {
    commentInputState.state.flatMap(comment => Callback.future(BackendApi.postComment[T](props.pathPrefix, comment)
      .map {
        case Right(image) => updateMyComments(image)
        case Left(Error(msg)) => Callback(println(msg))
      }))
  }

  def deleteComment(props: CommentProps, id: String)(event: ReactEventFromInput): react.Callback = {
    Callback.future(BackendApi.deleteComment[T](props.pathPrefix, id).map {
      case Right(image) => updateMyComments(image)
      case Left(Error(msg)) => Callback(println(msg))
    })
  }

  val updateMyComments = (image: T) => $.modState(cs => cs.copy(comments = image.getComments))
}
