package markdixon.name.frontend

import io.circe.Decoder
import io.circe.generic.auto._
import io.circe.parser._
import io.circe.syntax._
import japgolly.scalajs.react._
import japgolly.scalajs.react.extra.router.RouterCtl
import markdixon.name.{EmailLogin, Gallery, Image, NewComment}
import org.scalajs.dom.ext.{Ajax, AjaxException}
import org.scalajs.dom.raw.{File, FormData, XMLHttpRequest}

import scala.collection.immutable.Seq
import scala.concurrent.Future
import scala.scalajs.js.URIUtils
import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue

object BackendApi {

  val apiRoot = "https://www.markdixon.name"

  val galleriesUrl = s"$apiRoot/v1/galleries"
  val fbc = new JwtCookie()

  case class Error(message: String)

  def fetchGallery(routerCtl: RouterCtl[AppPage], name: String): Future[Either[Callback, Option[Gallery]]] = {
    Ajax.get(galleryByIdUrl(name)) map { xhr => xhr.status match {
      case 200 => Right(decode[Gallery](xhr.responseText).toOption)
      case _ => Right(None)
    }}
  }.recover{
    case AjaxException(xhr) => handleErrorState(xhr, None, routerCtl, Some(name), None)
    case _ => Right(None)
  }


  def fetchGalleries(routerCtl: RouterCtl[AppPage], selectedGallery: Option[String], selectedImage: Option[String]): Future[Either[Callback, Seq[(String, String)]]] = {
    Ajax.get(galleriesUrl) map { xhr => xhr.status match {
      case 200 =>
        Right(decode[Seq[(String, String)]](xhr.responseText).getOrElse(Seq[(String, String)]()))
      case x =>
        Right(Seq[(String, String)]())
    }}
  }.recover{
    case AjaxException(xhr) => handleErrorState(xhr, Seq[(String, String)](), routerCtl, selectedGallery, selectedImage)
    case _ => Right(Seq[(String, String)]())
  }


  private def handleErrorState[T](xhr: XMLHttpRequest, default: T, routerCtl: RouterCtl[AppPage], selectedGallery: Option[String], selectedImage: Option[String]): Either[Callback, T] = xhr.status match {
    case 401 => Left(fbc.redirectToLogin(routerCtl, selectedGallery, selectedImage))
    case _ => Right(default)
  }

  def postComment[T](pathPrefix: String, comment: String)(implicit d: Decoder[T]): Future[Either[Error,T]] = {
    val headers = Map("Content-Type" -> "application/json")
    Ajax.post(addImageCommentUrl(pathPrefix), NewComment(comment).asJson.noSpaces, headers = headers) map {
      xhr => xhr.status match {
        case 200 => decode[T](xhr.responseText) match {
          case Left(error) => Left(Error(error.getMessage))
          case Right(entity) => Right(entity)
        }
        case _ => Left(Error("Commenting failed, please try again"))
    }}
  }

  def deleteComment[T](pathPrefix: String, commentId: String)(implicit d: Decoder[T]): Future[Either[Error,T]] = {
    Ajax.delete(deleteImageCommentUrl(pathPrefix, commentId)) map { xhr => xhr.status match {
      case 200 => decode[T](xhr.responseText).left.map(e => Error(s"Could not decode response"))
      case 403 => Left(Error("You are not allowed to delete this comment"))
      case _ => Left(Error("Unable to delete right now"))
    }}
  }


  def postLogin(login: EmailLogin): Future[Either[Error, String]] = {
    val headers = Map("Content-Type" -> "application/json")
    Ajax.post(s"$apiRoot/v1/emaillogin", login.asJson.noSpaces, headers = headers) map {
      xhr => xhr.status match {
        case 200 => Right(xhr.responseText)
        case _ => Left(Error("Login failed, please try again"))
      }}
  }

  def uploadImage(galleryId: String, title: String, f: File): Future[Either[Error, Gallery]] = {
    val fd = new FormData()
    fd.append("image", f)
    fd.append("title", title)
    fd.append("type", f.`type`)
    Ajax.post(s"$apiRoot/v1/formimage/$galleryId", fd) map {
      xhr => xhr.status match {
        case 200 => decode[Gallery](xhr.responseText).left.map(_ => Error("Invalid gallery"))
        case _ => Left(Error("Error uploading "+ xhr.responseText))
      }}
  }.recover {
    case AjaxException(xhr) =>
      println(s"${xhr.status} ${xhr.responseText}")
      Left(Error("Error uploading "+ xhr.responseText))
  }

  def deleteImage(ic: ImageContext): Future[Either[Error, Gallery]] = {
    Ajax.delete(deleteImageUrl(ic)).map( xhr =>
      xhr.status match {
        case 200 => decode[Gallery](xhr.responseText).left.map(_ => Error("Invalid gallery"))
        case _ => Left(Error("Error deleting " + xhr.responseText))
      }
    ).recover {
      case AjaxException(xhr) =>
        println(s"${xhr.status} ${xhr.responseText}")
        Left(Error("Error deleting " + xhr.responseText))

    }
  }

  def rotateImage(ic: ImageContext, direction: String): Future[Either[Error, Image]] = {
    Ajax.post(rotateImageUrl(ic, direction)).map( xhr =>
      xhr.status match {
        case 200 => decode[Image](xhr.responseText).left.map(_ => Error("Invalid image"))
        case _ => Left(Error("Error rotating " + xhr.responseText))
      }
    ).recover {
      case AjaxException(xhr) =>
        println(s"${xhr.status} ${xhr.responseText}")
        Left(Error("Error rotating " + xhr.responseText))

    }
  }

  def newGallery(name: String, description: String): Future[Either[Error, Gallery]] = {
    val newGallery = Gallery(None, name, Some(description), images = List(), comments = Seq(), public = false)
    Ajax.post(newGalleryUrl, newGallery.asJson.noSpaces).map( xhr =>
      xhr.status match {
        case 201 => decode[Gallery](xhr.responseText).left.map(_ => Error("Invalid gallery response"))
        case _ => Left(Error("Error creating new gallery " + xhr.responseText))
      }
    ).recover {
      case AjaxException(xhr) =>
        println(s"${xhr.status} ${xhr.responseText}")
        Left(Error("Error rotating " + xhr.responseText))
    }
  }


  def thumbnailUrl(gallery: String, imageUrl: String) = s"$apiRoot/v1/image/${URIUtils.encodeURIComponent(gallery)}/${URIUtils.encodeURIComponent(imageUrl)}/thumbnail"

  private def addImageCommentUrl(prefix: String) =
    s"$apiRoot/v1/galleries/$prefix/comment"

  private def deleteImageCommentUrl(prefix: String, commentId: String) =
    s"$apiRoot/v1/galleries/$prefix/comment/${URIUtils.encodeURIComponent(commentId)}"

  private def newGalleryUrl = s"$apiRoot/v1/galleries"

  private def galleryByIdUrl(id: String) = s"$apiRoot/v1/galleries/${URIUtils.encodeURIComponent(id)}"

  private def deleteImageUrl(ic: ImageContext) = s"$apiRoot/v1/galleries/${ic.gallaryId}/image/${ic.index}"
  private def rotateImageUrl(ic: ImageContext, direction: String) = s"$apiRoot/v1/galleries/${ic.gallaryId}/image/${ic.index}/${direction}"

}


