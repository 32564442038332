package markdixon.name.frontend

import japgolly.scalajs.react.component.builder.Lifecycle.RenderScope
import japgolly.scalajs.react.ScalaComponent
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import markdixon.name.frontend.modals.{LoginPopup, LoginPopupProps}
import org.scalajs.dom.html.Div

object LoginComponent {

  case class State(isModalOpen: Boolean, loginState: String)

  case class LoginComponentProps(galleryId: Option[String], imageId: Option[String]) {
    def asUrl:String = (galleryId, imageId) match {
      case (Some(g), Some(i)) => s"$g/$i"
      case (Some(g), None) => s"$g"
      case (None, _) => ""
    }
  }

  val component = ScalaComponent.builder[LoginComponentProps]("Login")
    .initialState(State(false, ""))
    .renderS(render)
    .build


  def render($: RenderScope[LoginComponentProps, State, Unit], state: State): VdomTagOf[Div] = {
    <.div(
      <.div(^.cls := "row",
        <.div(^.cls := "col-12",
          <.p(
          "Thanks for you interest. I'd like to know who is looking at my photos, so I'd like to ask you to share your name and email with me. " +
            "I hope that's okay. If you choose to login with Google be assured that I can't see any information from your account apart from your name and email."
          )
        )
      ),
      <.div(^.cls := "row",
        <.div(^.cls := "col-sm login-link",
          <.a(^.href := google_url($.props),
            <.img(^.cls := "google", ^.id := "gSigninButton", ^.src := "web/btn_google_signin_dark_normal_web@2x.png", ^.alt := "Sign in with Google")
          )
        ),
        <.div(^.cls := "col-sm login-link",
          <.a(VdomAttr("data-toggle") := "modal", VdomAttr("data-target") := s"#loginModal", ^.href := "javascript: void(0)",
            <.img(^.cls := "email", ^.src := "web/envelope.svg"), " Login with Email"
          )
        )
      ),<.div(^.cls := "row",
        <.div(^.cls := "col-12",
          <.div(^.cls := "share-link login-link",
            "Icons made by",
            <.a(^.href:="https://www.flaticon.com/authors/gregor-cresnar", ^.title:="Gregor Cresnar", " Gregor Cresnar"),
            " from", <.a(^.href:="https://www.flaticon.com/",^.title:="Flaticon", " www.flaticon.com")," is licensed by",
            <.a(^.href:="http://creativecommons.org/licenses/by/3.0/",^.title:=" Creative Commons BY 3.0",^.target:="_blank"," CC 3.0 BY")
          )
        )
      ),
      LoginPopup.component(LoginPopupProps($.props.asUrl))
    )

  }

  def fb_url(state: Option[String]) = s"https://www.facebook.com/v3.0/dialog/oauth?client_id=437344856726318&redirect_uri=${BackendApi.apiRoot}/v1/oauth&state=${state.getOrElse("")}&scope=public_profile,email"

  def google_url(state: LoginComponentProps) = s"https://accounts.google.com/o/oauth2/v2/auth?client_id=889761185405-0lj0sl4449o8k525trrjt25lpke2v6q4.apps.googleusercontent.com&redirect_uri=${BackendApi.apiRoot}/v1/gauth&state=${state.asUrl}&scope=openid%20profile%20email&response_type=code"

}
