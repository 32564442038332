package markdixon.name.frontend

import japgolly.scalajs.react._
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.html.Div

object BlogComponent {

  val Component = ScalaComponent.builder[Props]("BlogComponent")
    .renderBackend[BlogComponentOps]
    .build

  final case class Props() {
    @inline def render: VdomElement = Component(this)
  }

  class BlogComponentOps($: BackendScope[Props, Unit]) {

    def render(): VdomTagOf[Div] = {
      <.div(
        BlurbComponent.Component(BlurbComponent.Props()),
        <.h1("Blog"),
        <.p("Coming Soon")
      )
    }
  }

}
