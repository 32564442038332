package markdixon.name.frontend

import japgolly.scalajs.react.CallbackTo
import org.scalajs.dom

import scala.scalajs.js.annotation.JSExport
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.extra.router.{Resolution, RouterConfigDsl, RouterCtl, _}
import markdixon.name.frontend.LoginComponent.LoginComponentProps
import org.scalajs.dom.html.Div

object App {

  @JSExport
  def main(args: Array[String]): Unit = {
    val fbc = new JwtCookie().checkLoggedIn()
    val isAdmin = fbc.exists(jwt => jwt.isAdmin.getOrElse(false))
    val userId = fbc.map(jwt => jwt.sub)

    def layout(c: RouterCtl[AppPage], r: Resolution[AppPage]): VdomTagOf[Div] =
      <.div(
        r.render()
      )

    val mustBeLoggedIn: CallbackTo[Boolean] = CallbackTo { new JwtCookie().checkLoggedIn().map(_.sub).isDefined }

    val config = RouterConfigDsl[AppPage].buildConfig { dsl =>
      import dsl._
      (
        staticRoute(root, Home) ~> renderR(routerCtl => GalleryComponent.component(GalleryProps(None, None, routerCtl, isAdmin)))
        | staticRoute(root / "#login", LoginPage) ~> render(LoginComponent.component(LoginComponentProps(None, None)))
        | staticRoute(root / "#links", LinksPage) ~> render(LinksComponent.Component(LinksComponent.Props(fbc.isDefined)))
        | staticRoute(root / "#recipe", RecipePage) ~> render(RecipeComponent.Component(RecipeComponent.Props()))
        | staticRoute(root / "#blog", BlogPage) ~> render(BlogComponent.Component(BlogComponent.Props()))
        | staticRoute(root / "#privacy", PrivacyPage) ~> render(PrivacyComponent.Component(PrivacyComponent.Props()))
        | (staticRoute(root / "#colander", ColanderPage) ~>
          renderR( routerCtl => ColanderComponent.Component(ColanderComponent.Props(userId, isAdmin, routerCtl))))
            .addConditionWithFallback(mustBeLoggedIn, redirectToPage(LoginPage)(SetRouteVia.HistoryReplace))
        | staticRoute(root / "#logout", LogoutPage) ~> render(LogoutComponent.Component(LogoutComponent.Props()))
        | dynamicRouteCT(root / "#login" / (string("[0-9a-z\\-]+") / string("[0-9a-z\\-]+").option).caseClass[LoginPageWithGallery] ) ~>
          dynRender(x => {
            val loginPage = x.asInstanceOf[LoginPageWithGallery]
            LoginComponent.component(LoginComponentProps(Some(loginPage.galleryId), loginPage.imageId))
          })
        | dynamicRouteCT(root / "#gallery" / (string("[0-9a-z\\-]+") / string("[0-9a-z\\-]+").option).caseClass[GalleryPage]) ~>
          dynRenderR((x, routerCtl) => {
            val galleryPage = x.asInstanceOf[GalleryPage]
            GalleryComponent.component(GalleryProps(Some(galleryPage.galleryId), galleryPage.imageId, routerCtl, isAdmin))
          }))
        .notFound(redirectToPage(Home)(SetRouteVia.HistoryReplace))
        .setTitle(appPage => s"markdixon.name | ${appPage.title}")
        .renderWith(layout)
    }

    val router = Router(BaseUrl.fromWindowOrigin, config)
    router().renderIntoDOM(dom.document.getElementById("gallery"))

  }
}


sealed trait AppPage {
  def title: String
}
case object Home extends AppPage {
  override def title: String = "Galleries"
}
case object LoginPage extends AppPage {
  override def title: String = "Login"
}
case object LinksPage extends AppPage {
  override def title: String = "Links"
}
case object RecipePage extends AppPage {
  override def title: String = "Recipe"
}
case object BlogPage extends AppPage {
  override def title: String = "Blog"
}
case object PrivacyPage extends AppPage {
  override def title: String = "Privacy Policy"
}
case object ColanderPage extends  AppPage {
  override def title: String = "Colander of Death"
}
case object LogoutPage extends AppPage {
  override def title: String = "Logout"
}

case class LoginPageWithGallery(galleryId: String, imageId: Option[String]) extends AppPage {
  override def title: String = "Login"
}
case class GalleryPage(galleryId: String, imageId: Option[String]) extends AppPage {
  override def title: String = "Galleries"
}
