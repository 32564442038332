package markdixon.name.frontend.modals

import japgolly.scalajs.react
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}
import markdixon.name.Gallery
import markdixon.name.frontend.BackendApi
import markdixon.name.frontend.BackendApi.Error
import org.scalajs.dom.raw.File

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue

object ImageUploadComponent {

  val component = ScalaComponent.builder[ImageUploadProps]("Login Form")
    .initialStateFromProps(_ => ImageUploadState(true, false, true, None, Seq.empty))
    .backend(new ImageUploadOps(_))
    .renderBackend
    .build
}

case class ImageUploadState(formVisible: Boolean, msgVisible: Boolean, buttonActive: Boolean, title: Option[String], files: Seq[File])

case class ImageUploadProps(galleryId: String, updateGalleryFn: Option[Gallery] => Callback)

class ImageUploadOps($: BackendScope[ImageUploadProps,ImageUploadState]) {

  def render(props: ImageUploadProps, state: ImageUploadState) = {

    <.div(^.cls := "modal fade", ^.tabIndex := -1, ^.id := "imageUploadModal", ^.role := "dialog", ^.aria.hidden := true, ^.aria.labelledBy := "imageUploadModal",
      <.div(^.cls := "modal-dialog", ^.role := "document",
        <.div(^.cls := "modal-content",
          <.div(^.cls := "modal-header",
            <.h5(^.cls := "modal-title", ^.id := s"imageUploadModalLabel", "Upload Image"),
            <.button(^.`type` := "button", ^.cls := "close", VdomAttr("data-dismiss") := "modal", ^.aria.label := "Close", ^.onClick ==> resetForm,
              <.span(^.aria.hidden := true, ^.dangerouslySetInnerHtml := "&times;")
            )
          ),
          <.div(^.cls := "modal-body",
            <.div(^.classSet("show" -> state.formVisible, "hide" -> !state.formVisible),
              <.form(^.id := "imageUploadForm",
                <.div(^.cls := "form-group",
                  <.label(^.`for` := "imageTitle", "Title"),
                  <.input(^.`type` := "text", ^.cls := "form-control", ^.id := "imageTitle", ^.onChange ==> changeTitle),
                  <.label(^.`for` := "imageFile", "Image"),
                  <.input(^.`type` := "file", ^.cls := "form-control", ^.id := "imageFile", ^.placeholder := "Enter file", ^.required := true, ^.multiple := true,  ^.onChange ==> changeFile)
                ),
              ),
            ),
            <.div(^.classSet("show" -> state.msgVisible, "hide" -> !state.msgVisible),
              "Done!"
            )
          ),
          <.div(^.cls := "modal-footer",
            <.button(^.`type` := "button", ^.cls := "btn btn-secondary", "Upload", ^.disabled := !state.buttonActive, ^.onClick ==> uploadImage(props,state))
          )
        )
      )
    )
  }

  def changeFile(event: ReactEventFromInput): Callback = {
    val files = (0 until event.target.files.length).map(i => event.target.files(i))
    $.modState(s => s.copy(files = files))
  }

  def changeTitle(event: ReactEventFromInput): Callback = {
    val title = event.target.value
    $.modState(s => s.copy(title = Some(title)))
  }

  def resetForm(event: ReactEventFromInput): Callback = {
    $.modState(s => s.copy(formVisible = true, msgVisible = false, buttonActive = true))
  }

  def uploadImage(p: ImageUploadProps, s: ImageUploadState)(event: ReactEventFromInput): react.Callback = {
    $.modState(s => s.copy(buttonActive = false)) >> Callback.traverse(s.files)(file =>
      Callback.future(BackendApi.uploadImage(p.galleryId, s.title.getOrElse(""), file).map {
        case Right(g) => p.updateGalleryFn(Some(g))
        case Left(Error(msg)) => Callback.warn("Image upload failed: " + msg )
      })) >> $.modState(s => s.copy(formVisible = false, msgVisible = true))
  }
}