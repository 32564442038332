package markdixon.name.frontend

import io.github.littlenag.scalajs.components.`react-bootstrap`.{Modal, ModalBody, ModalDialog, ModalHeader}
import japgolly.scalajs.react
import japgolly.scalajs.react.component.Scala.Unmounted
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import japgolly.scalajs.react.vdom.html_<^.{<, ^, _}
import markdixon.name.{Gallery, Image}

import scala.scalajs.js

object ImageView {

  val component = ScalaComponent.builder[ImageViewProps]("Image View")
    .initialStateFromProps(p => ImageViewState(p.image, p.context, true))
    .backend(new ImageViewOps(_))
    .renderBackend
    .build

  def apply(context: ImageContext, isOpen: Boolean, image: Image, closeFn: () => Callback): Unmounted[ImageViewProps, ImageViewState, ImageViewOps] =
    component(ImageViewProps(context, image, closeFn))
}

case class ImageViewState(image: Image, context: ImageContext, show: Boolean)

case class ImageContext(isAdminUser: Boolean, index: Int, gallaryId: String,
                        updateGalleryFn: Option[Gallery] => Callback)

case class ImageViewProps(context: ImageContext, image: Image, closeFn: () => Callback)

class ImageViewOps($: BackendScope[ImageViewProps, ImageViewState]) {

  def close(): Callback = {
    $.props.flatMap(_.closeFn()) >> $.modState(s => s.copy(show = false))
  }

  def render(content: ImageViewProps, s: ImageViewState) = {
    val image = s.image
    Modal(size = "xl", show = s.show, onHide = () => close() )(
      ModalHeader(closeButton = true, closeLabel = "&times;")(image.name),
      ModalBody()(
        <.div(^.cls := "container-fluid",
          <.div(^.cls := "row",
            <.div(^.cls := "col-12",
              image.mimeType match {
                case Some("video/mp4") =>
                  <.video(^.controls := true,
                    <.source(^.src := image.imageUrl, ^.`type` := "video/mp4" )
                  )
                case _ =>
                  <.div(^.cls := "fullimage", ^.style := js.Dictionary("backgroundImage" -> s"url('${image.imageUrl}')"))
              },
            )
          ),
          CommentComponentFactory.apply(s.context.gallaryId, s.context.index, content.image.comments),
          <.div(^.cls := "row",
            <.div(^.cls := "col-12 share-link",
              <.a(^.href := s"${BackendApi.apiRoot}/#gallery/${s.context.gallaryId}/${s.image.id}", "Share")
            ),
          )
        )
      )
    )
  }
}