package markdixon.name.frontend

import io.circe._
import io.circe.generic.auto._
import io.circe.parser._
import io.circe.syntax._
import japgolly.scalajs.react
import japgolly.scalajs.react._
import japgolly.scalajs.react.component.Scala.Component
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^.{<, _}
import markdixon.name.frontend.modals.{ConfirmComponent, ConfirmComponentProps}
import org.scalajs.dom.html.Div
import org.scalajs.dom.{CloseEvent, Event, MessageEvent, WebSocket}

import scala.reflect.ClassTag
import scala.scalajs.js

object ColanderComponent {

  val url = s"wss://www.markdixon.name/v1/colander"

  val Component: Component[Props, State, ColanderComponentOps, CtorType.Props] = ScalaComponent.builder[Props]("ColanderComponent")
    .initialState(State(None, 0, Setup, List(), SetupState("", List(), true), None,  Seq(), "", false, "30"))
    .renderBackend[ColanderComponentOps]
    .componentDidMount(_.backend.start())
    .componentWillUnmount(_.backend.end)
    .build

  final case class Props(userId: Option[String], isAdmin: Boolean, routerCtl: RouterCtl[AppPage]) {}

  sealed trait GameMode {
    def modeMessage: String
  }
  case object Setup extends GameMode {
    override def modeMessage: String = "Please enter some names of people to guess."
  }
  case class Playing(round: Int) extends GameMode {
    override def modeMessage: String = s"Game in progress. Round ${round}."
  }
  case object Round extends GameMode {
    override def modeMessage: String = "End of the round."
  }

  trait GameProto

  case class GameState(state: String, round: Int, players: List[Player], describing: Option[String]) extends GameProto {
    def getMode: GameMode = state match {
      case "setup" => Setup
      case "playing" => Playing(round)
      case "round" => Round
    }
  }

  case class Player(name: String, passes: Int, points: Int, connected: Boolean)

  case class Celeb(name: String) extends GameProto

  case class RemoveCeleb(name: String) extends GameProto

  case class Next(celeb: String, isPass: Boolean) extends GameProto

  case class MyState(celebs: List[String], describe: Seq[String], canPass: Boolean) extends GameProto

  case class Play() extends GameProto

  case class MyTurn() extends GameProto

  case class NewGame() extends GameProto

  case class Tick(seconds: Int) extends GameProto

  case class StartTimer(seconds: Int) extends GameProto

  case class Message[T <: GameProto](game: Int, payload: T)

  implicit def encodeMsg[T <: GameProto : Encoder](implicit ct: ClassTag[T]): Encoder[Message[T]] = (a: Message[T]) => Json.obj(
    "game" -> Json.fromInt(a.game),
    "type" -> Json.fromString(ct.runtimeClass.getSimpleName),
    "payload" -> a.payload.asJson)


  case class State(ws: Option[WebSocket], id: Int, gameMode: GameMode, players: List[Player], setupState: SetupState,
                   describing: Option[String], celeb: Seq[String], logMessage: String, canPass: Boolean, timer: String) {

    def log(line: String): State =
      copy(logMessage = logMessage + "\n" + line)
  }

  case class SetupState(newCeleb: String, myCelebs: List[String], validCeleb: Boolean)

  class ColanderComponentOps($: BackendScope[Props, State]) {

    def render(props: Props, state: State): VdomTagOf[Div] = {
      <.div(
        <.h1(<.img(^.src := "web/strainer.svg", ^.cls := "colander"), "Colander of Death"),
        <.p(s"${state.logMessage}"),
        <.div(^.cls := "row",
          <.div(^.cls := "col-12 col-md-6",
            <.h4("Players:"),
            <.table(^.cls := "table",
              <.thead(
                <.tr(
                  <.th(), <.th(), <.th("Passes"), <.th("Points")
                )
              ),
              <.tbody(
                state.players.map(p =>
                  <.tr(
                    <.td( if (p.connected) {
                      None
                    } else {
                      Some(<.img(^.src := "web/broken-link.svg", ^.cls := "disconnected"))
                    }.toTagMod),
                    <.td(p.name),<.td(p.passes), <.td(p.points)
                  )
                ).toTagMod
              )
            ),
          ),<.div(^.cls := "col-12 col-md-6",
            <.h5(^.cls := "d-flex justify-content-between align-items-center mb-3",
              <.span(state.gameMode.modeMessage),
              maybeTimer(props,state).toTagMod
            ),
            state.gameMode match {
              case Setup =>
                <.p(
                  <.h4("My Names:"),
                  <.ul(
                    state.setupState.myCelebs.map(p =>
                      <.li(p, <.a(^.onClick ==> submitRemoveCeleb(state, p), <.img(^.src := "web/delete-24px.svg")))
                    ).toTagMod
                  ),
                  <.div(^.cls := "row",
                    <.div(^.cls := "col",
                      <.div(^.cls := "input-group mb-3",
                        <.input(^.`type` := "text", ^.cls := "form-control", ^.id := "newPerson", ^.placeholder := "Famous Person", ^.value := state.setupState.newCeleb,  ^.onChange ==> changeNewCeleb),
                        <.button(^.`type` := "button", ^.id := "submitButton", ^.cls := "btn btn-secondary", ^.disabled := !state.setupState.validCeleb, 
                        if (state.setupState.validCeleb) "Add Person" else  "NO", ^.onClick ==> submitNewCeleb)),
                   )
                  ),
                <.div(^.cls := "row",
                    <.div(^.cls := "col",
                      <.button(^.`type` := "button", ^.cls := "btn btn-primary", "Start Game", VdomAttr("data-toggle") := "modal", VdomAttr("data-target") := s"#startGameModal"),
                  ConfirmComponent.component(
                    ConfirmComponentProps(
                      "startGameModal",
                      "Are you sure?",
                      "Once the round starts, no other players can enter names.",
                      "Start Round",
                      "Cancel",
                      startGame,
                      () => Callback.empty)
                  ))
                )
                )
              case Round =>
                <.p(
                  <.button(^.`type` := "button", ^.cls := "btn btn-primary", "Start Round", ^.onClick ==> play())
                )
              case Playing(_) if props.userId == state.describing => {
                <.p(
                  <.div(^.cls := "row",
                    <.div(^.cls := "col",
                      state.celeb.zipWithIndex.map { case (c, i) =>
                        <.div(^.cls := "card", VdomStyle("width") := "18rem",
                          <.div(^.cls := "card-header", "To Guess"),
                          <.div(^.cls := "card-body",
                            <.p(^.cls := "card-text", c),
                            <.div(^.cls := "row",
                              (if (i == state.celeb.size - 1 && state.canPass) {
                                Some(<.div(^.cls := "col",
                                  <.button(^.`type` := "button", ^.cls := "btn btn-secondary", "Pass", ^.value := c, ^.onClick ==> nextPass)))
                              } else None).toTagMod,
                              <.div(^.cls := "col",
                                <.button(^.`type` := "button", ^.cls := "btn btn-primary", "Next", ^.value := c, ^.onClick ==> nextCorrect)),
                            )
                          )
                        )
                      }.toTagMod,
                    ))
                  )
              }
              case Playing(_) =>
                <.p(^.cls := "row",
                  <.button(^.`type` := "button", ^.cls := "btn btn-primary", "It's My Turn", ^.onClick ==> myTurn)
                )
            },
          )
        ),
        newGameButton(props.isAdmin, state).toTagMod,
        <.br,<.br,
        <.div(^.cls := "row",
          <.div(^.cls := "col-12",
            <.div(^.cls := "share-link login-link",
              "Icons made by",
              <.a(^.href:="https://www.flaticon.com/authors/freepik", ^.title:="Freepik", " Freepik"),
              " from", <.a(^.href:="https://www.flaticon.com/",^.title:="Flaticon", " www.flaticon.com")
            )
          )
        )
      )
    }

    private val setupState = $.zoomState(_.setupState)(x => _.copy(setupState = x))

    private def newGameButton(isAdmin: Boolean, state: State): Option[TagMod] = if (isAdmin) {
      Some(
        <.p(
          <.button(^.`type` := "button", ^.cls := "btn btn-secondary", "New Game", VdomAttr("data-toggle") := "modal", VdomAttr("data-target") := s"#newGameModal"),
          ConfirmComponent.component(
            ConfirmComponentProps(
              "newGameModal",
              "Are you sure?",
              "Do you really want to end the current game?",
              "New Game",
              "Cancel",
              newGame(state),
              () => Callback.empty)
          )
        ),
      )
    } else None

    private def maybeTimer(props: Props, state: State): Seq[TagMod] =
      state.gameMode match {
        case Playing(_) => Seq(
          <.div(
            <.button(^.`type` := "button", ^.cls := "btn btn-primary", "Start Timer", ^.onClick ==> startTimer).when(props.userId == state.describing),
            <.span(^.cls := "badge badge-pill badge-primary", state.timer)
          ))
        case _ => Seq.empty
      }

    private def changeNewCeleb(event: ReactEventFromInput): Callback = {
      val blockList = Seq("duke of edinburgh", "prince phillip")
      val newCeleb = event.target.value
      if (blockList.exists(b => newCeleb.toLowerCase.contains(b))) {
        setupState.modState(_.copy(newCeleb = newCeleb, validCeleb = false)) 
      } else {
        setupState.modState(_.copy(newCeleb = newCeleb, validCeleb = true))
      }
    }

    private def submitNewCeleb(event: ReactEventFromInput): react.Callback = {
      $.state.flatMap(s => if (s.setupState.newCeleb.trim.nonEmpty) {
        sendMessage(Message(s.id, Celeb(s.setupState.newCeleb))) >>
        setupState.modState(_.copy(newCeleb = ""))
      } else Callback.empty) 
    }

    private def submitRemoveCeleb(state: State, celeb:String)(event: ReactEventFromInput): react.Callback = {
      sendMessage(Message(state.id, RemoveCeleb(celeb)))
    }

    private def nextCorrect(event: ReactEventFromInput): react.Callback = {
      $.state.flatMap(state =>
        sendMessage(Message(state.id, Next(event.target.value, false))) >> 
        $.modState(_.copy(celeb = Seq.empty)))
    }

    private def nextPass(event: ReactEventFromInput): react.Callback = {
      $.state.flatMap(state => 
          sendMessage(Message(state.id, Next(event.target.value, true))) >>
          $.modState(_.copy(celeb = Seq.empty)))
    }

    private def play()(event: ReactEventFromInput): react.Callback = {
      sendPlayMessage()
    }

    private def startGame(): Callback = sendPlayMessage()

    private def sendPlayMessage(): react.Callback = {
      $.state.flatMap(state => sendMessage(Message(state.id, Play())))
    }

    private def myTurn(event: ReactEventFromInput): react.Callback = {
      $.state.flatMap(state =>
        sendMessage(Message(state.id, MyTurn())) >>
          $.modState(_.copy(timer = "30"))
      )
    }

    private def startTimer(event: ReactEventFromInput): react.Callback = {
      $.state.flatMap(state => sendMessage(Message(state.id, StartTimer(30))))
    }

    private def newGame(state: State): () => react.Callback = { () =>
      sendMessage(Message(state.id, NewGame())) >>
        setupState.modState(_.copy(myCelebs = List.empty)) 
    }

    private def sendMessage[T <: GameProto](message: Message[T])(implicit e: Encoder[Message[T]]): react.Callback = {
      $.state.flatMap(state =>
        if (state.ws.map(_.readyState > 1).getOrElse(true)) {
          start(Seq(message.asJson.noSpaces))
        } else {
          state.ws.foreach(w => w.send(message.asJson.noSpaces))
          Callback.empty
        }
      )
    }

    def start(messageQueue: Seq[String] = Seq.empty): Callback = {

      // This will establish the connection and return the WebSocket
      def connect = CallbackTo[WebSocket] {

        // Get direct access so WebSockets API can modify state directly
        // (for access outside of a normal DOM/React callback).
        // This means that calls like .setState will now return Unit instead of Callback.
        val direct = $.withEffectsImpure

        // These are message-receiving events from the WebSocket "thread".

        def onopen(e: Event): Unit = {
          // Indicate the connection is open
          direct.modState(_.log("Connected"))
          messageQueue.foreach(m => direct.state.ws.foreach(w => w.send(m)))
        }

        def onmessage(e: MessageEvent): Unit = {
          e.data.toString.split("\n").foreach(line =>
            parse(line).map(json => json.hcursor.downField("type").as[String].map {
              case "GameState" => json.as[Message[GameState]].map(gs =>
                direct.modState(_.copy(id = gs.game, players = gs.payload.players, gameMode = gs.payload.getMode, describing = gs.payload.describing)))
              case "MyState" => json.as[Message[MyState]].map( c =>
                  direct.modState(s => s.copy(setupState = s.setupState.copy(myCelebs = c.payload.celebs), celeb = c.payload.describe, canPass = c.payload.canPass)))
              case "Tick" => json.as[Message[Tick]].map( c => {
                  val time = if (c.payload.seconds > 0) c.payload.seconds.toString else "STOP"
                  direct.modState(s => s.copy(timer = time))})
            })
          )
        }

        def onerror(e: Event): Unit = {
          // Display error message
          val msg: String =
            e.asInstanceOf[js.Dynamic]
              .message.asInstanceOf[js.UndefOr[String]]
              .fold(s"Error occurred!")("Error occurred: " + _)
          direct.modState(_.log(msg))
        }

        def onclose(e: CloseEvent): Unit = {
          // Close the connection
          direct.modState(_.copy(ws = None, logMessage = s"""Closed. Reason = "${e.reason}" Code = ${e.code}"""))
        }

        // Create WebSocket and setup listeners
        val ws = new WebSocket(url)
        ws.onopen = onopen _
        ws.onclose = onclose _
        ws.onmessage = onmessage _
        ws.onerror = onerror _
        ws
      }

      // Here use attempt to catch any exceptions in connect
      connect.attempt.flatMap {
        case Right(ws) => $.modState(_.copy(logMessage = s"Connecting to virtual colander...", ws = Some(ws)))
        case Left(error) => $.modState(_.copy(logMessage = s"Error connecting: ${error.getMessage}"))
      }
    }


    def end: Callback = {
      def closeWebSocket = $.state.map(_.ws.foreach(_.close())).attempt
      def clearWebSocket = $.modState(_.copy(ws = None))
      closeWebSocket >> clearWebSocket
    }
  }

}
