package markdixon.name.frontend

import japgolly.scalajs.react.{CtorType, _}
import japgolly.scalajs.react.component.Scala.Component
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.html.Div

import scala.collection.immutable.ListMap

object LinksComponent {

  val Component: Component[Props, Unit, LinksComponentOps, CtorType.Props] = ScalaComponent.builder[Props]("LinksComponent")
    .renderBackend[LinksComponentOps]
    .build

  case class Link(text: String, url: String, isSensitive: Boolean = false)

  final case class Props(shouldShowSensitiveLinks: Boolean) {
    @inline def render: VdomElement = Component(this)

    val links = ListMap(
      "My Other Pages" -> List(
        Link("Github", "https://www.github.com/mnd999", isSensitive = true ),
        Link("LinkedIn", "https://www.linkedin.com/in/mark-dixon-0048b83/", isSensitive = true ),
        Link("Neo4j (Where I work)", "https://www.neo4j.com", isSensitive = true ),
        Link("last.fm", "https://www.last.fm/user/mnd999")
      ),
      "Recipes" ->  List(
        Link("Agrodolce sausage, squash and crispy sage tray bake (number 5)", "https://www.theguardian.com/lifeandstyle/2016/feb/01/cheap-meals-food-2-pounds-a-head-cook-budget"),
        Link("Biscoff Cake", "https://www.janespatisserie.com/2016/05/05/biscoff-cake/"),
        Link("Black-eyed beans with chard and green herb smash", "#recipe"),
        Link("Butter Beans with Fennel, Lemon and Tomato", "https://wilderhoodwatch.org/projects/sustainable-eating/recipe-blog/butter-beans-with-fennel-lemon-and-tomato-v"),
        Link("Candlemas Slow-roasted shoulder of lamb", "https://www.bbcgoodfood.com/recipes/slow-roasted-shoulder-lamb"),
        Link("Paneer Masala", "https://www.olivemagazine.com/recipes/vegetarian/paneer-masala/"),
        Link("Spiced Vegetable Biryani", "https://www.bbcgoodfood.com/recipes/spiced-vegetable-biryani"),
        Link("Vegan Sticky Tofu Bao", "https://allplants.com/blog/recipes/vegan-sticky-tofu-bao-recipe")
      )
    )

  }

  class LinksComponentOps($: BackendScope[Props, Unit]) {

    def render(props: Props): VdomTagOf[Div] = {
      <.div(
        BlurbComponent.Component(BlurbComponent.Props()),
        <.h1("Links"),
        props.links.flatMap { case (category, links) =>
          List(<.h3(category),
          <.ul(
            links.collect { case Link(title, url, isSensitive) if props.shouldShowSensitiveLinks || !isSensitive =>
              List(<.li(
                <.a(^.href := url, title)
              ))
            }.flatten.toTagMod
          ))
        }.toTagMod
      )
    }
  }

}

