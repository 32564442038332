package markdixon.name.frontend

import japgolly.scalajs.react._
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.html.Div

object PrivacyComponent {

  val Component = ScalaComponent.builder[Props]("PrivacyComponent")
    .renderBackend[PrivacyComponentOps]
    .build

  final case class Props() {
    @inline def render: VdomElement = Component(this)
  }

  class PrivacyComponentOps($: BackendScope[Props, Unit]) {

    def render(): VdomTagOf[Div] = {
      <.div(
        <.h1("Privacy Policy"),
        <.ol(
          <.li("Introduction",
            <.ol(
              <.li("We are committed to safeguarding the privacy of our website visitors."),
              <.li("This policy applies where we are acting as a data controller with respect to the personal data of our website visitors; in other words, where we determine the purposes and means of the processing of that personal data."),
              <.li("We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website, we will ask you to consent to our use of cookies when you first visit our website."),
              <.li("""In this policy, "we", "us" and "our" refer to Mark Dixon.""")
            )),
          <.li("Credit",
            <.ol(<.li("This document was created using a template from ",<.a(^.href := "https://seqlegal.com","SEQ Legal"), ")."))),
          <.li("How we use your personal data",
            <.ol(
              <.li("In this Section 3 we have set out:",
                <.ol(^.`type` := "a",
                  <.li("the general categories of personal data that we may process;"),
                  <.li("in the case of personal data that we did not obtain directly from you, the source and specific categories of that data;"),
                  <.li("the purposes for which we may process personal data; and"),
                  <.li("the legal bases of the processing.")
                )),
              <.li("""We may process data about your use of our website("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our server logs. This usage data may be processed for the purposes of analysing the use of the website. The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services."""),
              <.li("""We may process your account data ("account data").[ The account data may include your name and email address. The source of the account data is you via your Facebook login. The account data may be processed for the purposes of ensuring the security of our website and maintaining back-ups of our databases. The legal basis for this processing is our legitimate interests, namely the proper administration of our website."""),
              <.li("""We may process information that you post for publication on our website or through our services ("publication data"). The publication data may be processed for the purposes of enabling such publication and administering our website and services. The legal basis for this processing is consent.""",
                <.li("""We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.""",
                  <.li("""In addition to the specific purposes for which we may process your personal data set out in this Section 3, we may also process [any of your personal data] where such processing is necessary[ for compliance with a legal obligation to which we are subject, or] in order to protect your vital interests or the vital interests of another natural person."""),
                  <.li("""Please do not supply any other person's personal data to us, unless we prompt you to do so.""")
                )
              ))),
          <.li("Providing your personal data to others",
            <.ol(
              <.li("""We may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure."""),
            )),
          <.li("International transfers of your personal data",
            <.ol(
              <.li("In this Section 5, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA)."),
              <.li("You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others."),
            )),
          <.li("Retaining and deleting personal data",
            <.ol(
              <.li("""This Section 6 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data."""),
              <.li("""Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes."""),
              <.li("""We will retain your personal data as follows:""",
                <.ol(^.`type` := "a",
                  <.li("Usage data will be retained for a minimum period of 1 month following the date it is collected, and for a maximum period of 1 year following the date it is collected."),
                  <.li("Account data will be retained for a minimum period of 6 months following the date it is collected, and for a maximum period of 6 months following the date of the account being removed from the site.")
                )),
              <.li("""In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:""",
                <.ol(^.`type` := "a",
                  <.li("""the period of retention of publication data will be determined based on the period the content to which it relates remains active on the site, or until consent is withdrawn"""),
                )),
              <.li("""Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person."""),
            )),
          <.li("Amendments",
            <.ol(
              <.li("We may update this policy from time to time by publishing a new version on our website."),
              <.li("We may notify you of significant changes to this policy by email.")
            )),
          <.li("Your rights",
            <.ol(
              <.li("""In this Section 8, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights."""),
              <.li("""Your principal rights under data protection law are:""",
                <.ol(^.`type` := "a",
                  <.li("the right to access;"),
                  <.li("the right to rectification;"),
                  <.li("the right to erasure;"),
                  <.li("the right to restrict processing;"),
                  <.li("the right to object to processing;"),
                  <.li("the right to data portability;"),
                  <.li("the right to complain to a supervisory authority; and"),
                  <.li("the right to withdraw consent.")
                )),
              <.li("""You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee."""),
              <.li("""You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed."""),
              <.li("""In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims."""),
              <.li("""In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defence of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest."""),
              <.li("""You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defence of legal claims."""),
              <.li("""To the extent that the legal basis for our processing of your personal data is:""",
                <.ol(^.`type` := "a",
                  <.li("consent; or"),
                  <.li("""that the processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract, and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others."""),
                )),
              <.li("""If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement."""),
              <.li("""To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal."""),
              <.li("""You may exercise any of your rights in relation to your personal data by written notice to us.""")
            )),
          <.li("""About cookies""",
            <.ol(
              <.li("""A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server."""),
              <.li("""Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.""")
            )),
          <.li("Cookies that we use",
            <.ol(
              <.li("We use cookies for the following purposes",
                <.ol(^.`type` := "a",
                  <.li("""authentication - we use cookies to identify you when you visit our website and as you navigate our website(cookies used for this purpose are: 'login');""")
                ))
            )),
          <.li("Our details",
            <.ol(
              <.li("This website is owned and operated by Mark Dixon."),
              <.li("You can contact us:",
                <.ol(^.`type` := "a",
                  <.li("by email, using ", <.a(^.href := "mailto:enquiry@markdixon.name", "enquiry@markdixon.name"))
                ))
            ))
        )
      )
    }
  }

}
