package markdixon.name.frontend

import japgolly.scalajs.react
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, CallbackTo, ReactEventFromHtml, ScalaComponent}

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue
import markdixon.name.Gallery
import markdixon.name.frontend.modals.{ImageUploadComponent, ImageUploadProps, NewGalleryComponent, NewGalleryProps}


object GalleryComponent {

  val component = ScalaComponent.builder[GalleryProps]("Image Gallery")
    .initialState(GalleryState.empty)
    .backend(new GalleryOps(_))
    .renderBackend
    .componentDidMount($ => $.backend.loadGalleries($.props))
    .build
}

case class GalleryProps(galleryId: Option[String], imageId:Option[String], routerCtl: RouterCtl[AppPage], isAdminUser: Boolean)

case class GalleryState(galleries: Seq[(String, String)], selectedGallery: Option[Gallery])

object GalleryState {
  val empty: GalleryState = GalleryState(Seq(), None)
}

class GalleryOps($: BackendScope[GalleryProps, GalleryState]) {

  private val selectedGalleryState = $.zoomState(_.selectedGallery)(x => _.copy(selectedGallery = x))

  private val galleryListState = $.zoomState(_.galleries)(x => _.copy(galleries = x))


  private def galleryChange(event: ReactEventFromHtml): react.Callback = {
    val galleryId = event.target.getAttribute("data-gallery")
    $.props.flatMap(p => updateGallery(galleryId, p.routerCtl))
  }

  def render(props: GalleryProps, s: GalleryState) =
    <.div(
      BlurbComponent.Component(BlurbComponent.Props()),
      <.div(^.cls := "row",
        <.div(^.cls := "col-lg-1 col-sm-6",
          <.div( ^.cls := "dropdown",
            <.button(^.cls := "btn btn-secondary dropdown-toggle", ^.`type`:="button", ^.id := "dropdownMenuButton", VdomAttr("data-toggle") := "dropdown", VdomAttr("aria-haspopup") := "true", VdomAttr("aria-expanded"):="false",
              "Gallery"
            ),
            <.div(^.cls := "dropdown-menu", VdomAttr("aria-labelledby") := "dropdownMenuButton",
              s.galleries.map { g =>
                <.a(^.cls := "dropdown-item", ^.href := "#", ^.onClick ==> galleryChange, VdomAttr("data-gallery") := g._1, g._2)
              }.toTagMod
            ),
          ),
        ),
        newButton(props.isAdminUser)
      ),
      s.selectedGallery match {
        case None =>
          <.div(^.cls := "row",
            <.div(^.cls := "col-12",
              <.h1("Loading")
            )
          )
        case Some(g) =>
          <.div(
            <.div(^.cls := "row",
              <.div(^.cls := "col-11",
                <.h1(g.name, if (!g.public) " - Not Public" else "")
              ),
              uploadButton(props.isAdminUser, s),
            ),
            <.div(^.cls := "row",
              <.div(^.cls := "col-12",
                <.h6(g.description)
              )
            ),
            g.images.zipWithIndex.grouped(4).map { is =>
              <.div(^.cls := "row",
                is.map { case(image, index) => <.div(^.cls := "col-12 col-sm-6 col-md-4 col-lg-3",
                  ImageComponent.component(ImageComponentProps(ImageContext(props.isAdminUser, index, g.id.get, selectedGalleryState.setState), image, props.imageId))) }.toTagMod
              )
            }.toTagMod,
            CommentComponentFactory.apply(g.id.get, g.comments),
            <.div(^.cls := "row",
              <.div(^.cls := "col-12 share-link",
                <.a(^.href := s"${BackendApi.apiRoot}/#gallery/${g.id.getOrElse("")}/", "Share")
              ),
            )
          )
      }
    )

  def uploadButton(isAdmin: Boolean, state: GalleryState): Option[VdomNode] = if (isAdmin) {
    state.selectedGallery.flatMap(_.id.map( gid =>
      <.div(^.cls := "col-1",
        <.a(VdomAttr("data-toggle") := "modal", VdomAttr("data-target") := s"#imageUploadModal", ^.href := "javascript: void(0)",
          <.img(^.src := "web/cloud-computing.svg", ^.width := "138", ^.height := "138")
        ),
        ImageUploadComponent.component(ImageUploadProps(gid, selectedGalleryState.setState))
      )
    ))
  } else None

  def newButton(isAdmin: Boolean): Option[VdomNode] = if (isAdmin) {
    Some(<.div( ^.cls := "col-lg-2 col-sm-6",
      <.button(^.cls := "btn btn-secondary", ^.`type`:="button", ^.id := "newGalleryButton", VdomAttr("data-toggle") := "modal", VdomAttr("data-target") := s"#newGalleryModal",
        "New Gallery"
      ),
      NewGalleryComponent.component(NewGalleryProps(addGallery))
    ))
  } else None

  def addGallery(id: String, name: String): Callback = {
    galleryListState.modState(gs => gs :+ (id->name))
  }

  private def updateGallery(galleryId: String, routerCtl: RouterCtl[AppPage]): react.Callback = Callback.future {
    BackendApi.fetchGallery(routerCtl, galleryId).map {
      case Right(g) => selectedGalleryState.setState(g)
      case Left(cb) => cb
    }
  }

  def loadGalleries(galleryProps: GalleryProps): Callback =
    Callback.future(BackendApi.fetchGalleries(galleryProps.routerCtl, galleryProps.galleryId, galleryProps.imageId).map{
      case Right(galleries) =>
        galleryListState.setState(galleries) >>
          $.props.flatMap {
            case GalleryProps(Some(initialValue), image, _, _) =>
              updateGallery(initialValue, galleryProps.routerCtl)
            case GalleryProps(None, _,  _, _) =>
              galleries.headOption match {
                case Some(g) => updateGallery(g._1, galleryProps.routerCtl)
                case None => Callback.empty
              }
          }
      case Left(redirect) => redirect
    })

}