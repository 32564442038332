package markdixon.name.frontend

import japgolly.scalajs.react._
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.html.Div

object RecipeComponent {

  val Component = ScalaComponent.builder[Props]("RecipeComponent")
    .renderBackend[BlogComponentOps]
    .build

  final case class Props() {
    @inline def render: VdomElement = Component(this)
  }

  class BlogComponentOps($: BackendScope[Props, Unit]) {

    def render(): VdomTagOf[Div] = {
      <.div(
        <.h1("Black-eyed beans with chard and green herb smash"),
        <.img(^.src := "https://storage.googleapis.com/images.markdixon.name/847f1b45-4cc9-4de4-b408-aacbf4e8e18e"),
        <.p("I’ll be cooking again this Saturday at 6pm on Instagram live and this week I’m making my black eyed beans with chard and green herb smash from my 2nd book “A Modern Way to Cook”. It’s an easy stew that has its roots in Palestine, topped with a green herb smash that you could put on anything and it would make it better. As ever you can swap out for what you have – any beans are welcome, any soft herb for the smash, tinned tomatoes in place of passata."),
        <.p("BLACK EYED BEANS WITH CHARD AND GREEN HERB SMASH"),
        <.p("  SERVES 4"),
        <.p("FOR THE BEANS"),
        <.p("    1 leek"),
        <.p("    1 tablespoon coconut oil or olive oil"),
        <.p("2 cloves of garlic"),
        <.p("  a good pinch of chilli powder or chopped dried chilli"),
        <.p("  2 × 400g tins of black-eyed beans"),
        <.p("  1 teaspoon vegetable stock powder, or 1⁄2 a stock cube"),
        <.p("  250ml of passata"),
        <.p("  a good grating of nutmeg"),
        <.p("½ a teaspoon of ground cinnamon"),
        <.p("1⁄2 an unwaxed lemon"),
        <.p("  200g bunch of Swiss or rainbow chard or greens of your choice"),
        <.p(""),
        <.p("FOR THE HERB SMASH"),
        <.p("  A small bunch of fresh coriander"),
        <.p("  A small bunch of fresh parsley"),
        <.p("  2 green chillies"),
        <.p("2 cloves of garlic"),
        <.p("  30g shelled walnuts"),
        <.p("  1 tablespoon runny honey or maple syrup"),
        <.p("2 tablespoons good olive oil"),
        <.p("the juice of 1⁄2 a lemon"),
        <.p(""),
        <.p("Get all your ingredients together. Put a large saucepan on the heat."),
        <.p(""),
        <.p("  Wash and finely slice the leek. Add to the saucepan with a tablespoon of coconut or olive oil and cook for a couple of minutes until soft and sweet."),
        <.p(""),
        <.p("Finely slice the garlic and add to the pan with the chilli powder or dried chilli and cook for a couple of minutes, until the garlic is beginning to brown. Add the black-eyed beans with their liquid, the stock powder or cube, 200ml hot water and the passata and bring to a simmer. Grate in the nutmeg and add the cinnamon, squeeze in the juice of half the lemon, add the squeezed lemon half to the pan and simmer for 10 minutes or so. Meanwhile, strip the leaves from the chard stalks. Finely slice the stalks and add them to the pan, then finely shred the leaves and put to one side."),
        <.p(""),
        <.p("Put all the ingredients for the herb smash into a food processor, squeeze in the juice of the other half of the lemon and blitz until you have a smooth grassy paste. Season well with salt and pepper."),
        <.p(""),
        <.p("  Once the black beans are soft and flavourful and the liquid has reduced to a thick soup-like consistency, stir in the chard leaves, season well with salt and pepper and leave to cook for a couple of minutes. Scoop into deep bowls and spoon over the herb smash. If you’re really hungry, some rice or flatbread would go well."),
        <.p(""))
    }
  }

}
