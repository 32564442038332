package markdixon.name.frontend.modals

import japgolly.scalajs.react.vdom.html_<^.{^, _}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}
import markdixon.name.frontend.BackendApi
import markdixon.name.frontend.BackendApi.Error

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue

object NewGalleryComponent {

  val component = ScalaComponent.builder[NewGalleryProps]("New Gallery Form")
    .initialStateFromProps(_ => NewGalleryState(true, false, true, None, None))
    .backend(new NewGalleryOps(_))
    .renderBackend
    .build
}

case class NewGalleryState(formVisible: Boolean, msgVisible: Boolean, buttonActive: Boolean, title: Option[String], description: Option[String])

case class NewGalleryProps(updateGalleriesFn: (String, String) => Callback)

class NewGalleryOps($: BackendScope[NewGalleryProps,NewGalleryState]) {

  def render(props: NewGalleryProps, state: NewGalleryState) = {

    <.div(^.cls := "modal fade", ^.tabIndex := -1, ^.id := "newGalleryModal", ^.role := "dialog", ^.aria.hidden := true, ^.aria.labelledBy := "newGalleryModal",
      <.div(^.cls := "modal-dialog", ^.role := "document",
        <.div(^.cls := "modal-content",
          <.div(^.cls := "modal-header",
            <.h5(^.cls := "modal-title", ^.id := s"newGalleryModalLabel", "New Gallery"),
            <.button(^.`type` := "button", ^.cls := "close", VdomAttr("data-dismiss") := "modal", ^.aria.label := "Close", ^.onClick ==> resetForm,
              <.span(^.aria.hidden := true, ^.dangerouslySetInnerHtml := "&times;")
            )
          ),
          <.div(^.cls := "modal-body",
            <.div(^.classSet("show" -> state.formVisible, "hide" -> !state.formVisible),
              <.form(^.id := "imageUploadForm",
                <.div(^.cls := "form-group",
                  <.label(^.`for` := "galleryTitle", "Title"),
                  <.input(^.`type` := "text", ^.cls := "form-control", ^.id := "galleryTitle", ^.required := true, ^.onChange ==> changeTitle),
                  <.label(^.`for` := "galleryDescription", "Description"),
                  <.input(^.`type` := "test", ^.cls := "form-control", ^.id := "galleryDescription", ^.required := false, ^.onChange ==> changeDescription)
                ),
              ),
            ),
          ),
          <.div(^.cls := "modal-footer",
            <.button(^.`type` := "button", ^.cls := "btn btn-secondary", "Create", ^.disabled := !state.buttonActive, ^.onClick ==> newGallery(props,state), VdomAttr("data-dismiss") := "modal")
          )
        )
      )
    )
  }

  def changeDescription(event: ReactEventFromInput): Callback = {
    val description = event.target.value
    $.modState(s => s.copy(description = Some(description)))
  }

  def changeTitle(event: ReactEventFromInput): Callback = {
    val title = event.target.value
    $.modState(s => s.copy(title = Some(title)))
  }

  def resetForm(event: ReactEventFromInput): Callback = {
    $.modState(s => s.copy(formVisible = true, buttonActive = true))
  }

  def newGallery(props: NewGalleryProps, state: NewGalleryState)(event: ReactEventFromInput): Callback = {
    $.modState(s => s.copy(buttonActive = false)) >>
      Callback.future(BackendApi.newGallery(state.title.getOrElse(""), state.description.getOrElse("")).map {
        case Right(g) => props.updateGalleriesFn(g.id.get, g.name) >> $.modState(s => s.copy(formVisible = false, buttonActive = true))
        case Left(Error(msg)) => Callback(false)
      })
  }
}
