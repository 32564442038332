package markdixon.name.frontend

import japgolly.scalajs.react._
import japgolly.scalajs.react.vdom.html_<^._
import org.scalajs.dom.html.Div

object BlurbComponent {

  val Component = ScalaComponent.builder[Props]("BlurbComponent")
    .renderBackend[BlurbComponentOps]
    .build

  final case class Props() {
    @inline def render: VdomElement = Component(this)
  }

  class BlurbComponentOps($: BackendScope[Props, Unit]) {

    def render(): VdomTagOf[Div] = {
      <.div(^.cls := "jumbotron",
        <.p("""This is a little site I put together after I got a bit annoyed with Facebook. The trouble is, if you put anything on social media, the social media company gets all
          that content and that wasn't very compelling anymore. To keep sharing my photos with my friends, I have decided to take it back to the '90s
          and build my own homepage. Like all '90s homepages, the site is a work in progress and I'm hoping to add a few more features in the near future. If you have any thoughts, good or bad please
          do email me, any kind of feedback is good.""")
      )
    }
  }


}
