package markdixon.name.frontend.modals

import japgolly.scalajs.react
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}

object ConfirmComponent {

  val component = ScalaComponent.builder[ConfirmComponentProps]("New Gallery Form")
    .initialStateFromProps(_ => ConfirmComponentState(true))
    .backend(new ConfirmComponentOps(_))
    .renderBackend
    .build
}

case class ConfirmComponentState(formVisible: Boolean)

case class ConfirmComponentProps(id: String,
                                 title: String,
                                 body: String,
                                 yesButtonText: String = "Confirm",
                                 noButtonText: String = "Cancel",
                                 confirmAction: () => Callback,
                                 cancelAction: () => Callback)

class ConfirmComponentOps($: BackendScope[ConfirmComponentProps,ConfirmComponentState]) {

  def render(props: ConfirmComponentProps, state: ConfirmComponentState) = {

    <.div(^.cls := "modal fade", ^.tabIndex := -1, ^.id := props.id, ^.role := "dialog", ^.aria.hidden := true, ^.aria.labelledBy := props.id,
      <.div(^.cls := "modal-dialog", ^.role := "document",
        <.div(^.cls := "modal-content",
          <.div(^.cls := "modal-header",
            <.h5(^.cls := "modal-title", ^.id := s"${props.id}Label", props.title),
            <.button(^.`type` := "button", ^.cls := "close", VdomAttr("data-dismiss") := "modal", ^.aria.label := "Close", ^.onClick ==> handleCancelClickEvent(props.cancelAction),
              <.span(^.aria.hidden := true, ^.dangerouslySetInnerHtml := "&times;")
            )
          ),
          <.div(^.cls := "modal-body",
            <.div(^.classSet("show" -> state.formVisible, "hide" -> !state.formVisible),
              <.p(props.body)
            ),
          ),
          <.div(^.cls := "modal-footer",
            <.button(^.`type` := "button", ^.cls := "btn btn-primary",  ^.onClick ==> handleOkClickEvent(props.confirmAction), VdomAttr("data-dismiss") := "modal", props.yesButtonText),
            <.button(^.`type` := "button", ^.cls := "btn btn-secondary",  ^.onClick ==> handleCancelClickEvent(props.cancelAction), VdomAttr("data-dismiss") := "modal", props.noButtonText)
          )
        )
      )
    )
  }

  def handleOkClickEvent(fn: () => Callback)(event: ReactEventFromInput): Callback = {
    fn()
  }

  def handleCancelClickEvent(fn: () => Callback)(event: ReactEventFromInput): Callback = {
    fn()
  }
}
