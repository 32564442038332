package markdixon.name.frontend

import japgolly.scalajs.react
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromHtml, ReactMouseEvent, ScalaComponent}
import markdixon.name.Image
import org.scalajs.dom.html.Div

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue

object ImageComponent {

  val component = ScalaComponent.builder[ImageComponentProps]("Image")
    .getDerivedStateFromPropsAndState[State](deriveState)
    .backend(new ImageComponentOps(_))
    .renderBackend
    .build


    def deriveState(props: ImageComponentProps, state: Option[State]): State = state match {
      case Some(s) if s.image != props.image => State(props.image, false)
      case None => State(props.image, props.visibleImageId.contains(props.image.id))
      case Some(s) => s
    }

}

case class State(image: Image, modalVisible: Boolean)
case class ImageComponentProps(imageContext: ImageContext, image: Image, visibleImageId: Option[String])

class ImageComponentOps($: BackendScope[ImageComponentProps, State]) {

  def render(props: ImageComponentProps, state: State): VdomTagOf[Div] = {
    val context = props.imageContext
    val image = state.image
    <.div(
      <.div(^.cls := "thumbnailImgContainer",
        <.a(^.onClick ==> clickImage,
          <.div(VdomStyle("backgroundImage") := thumbnailUrl(context.gallaryId, image), ^.cls := "thumbnailImg"
          ),
        ),
      ),
      adminPanel(context),
      if (state.modalVisible) {
        ImageView.component(ImageViewProps(context, image, () => $.modState(state => state.copy(modalVisible = false))))
      } else {
        EmptyVdom
      }
    )
  }

  def clickImage(e: ReactMouseEvent): Callback = {
    e.preventDefault()
    $.modState(state => state.copy(modalVisible = true))
  }

  private def thumbnailUrl(galleryId: String, image: Image) = {
    "url(" + (if (image.thumbnailUrl.nonEmpty) {
      image.thumbnailUrl
    } else {
      BackendApi.thumbnailUrl(galleryId, image.imageUrl)
    }) + ")"
  }

  private def adminPanel(imageContext: ImageContext): Option[VdomNode] = if (imageContext.isAdminUser) {
    Some(
      <.div(^.cls := "row",
        <.div(^.cls := "col-4", <.a(^.onClick ==> rotateLeftClicked, <.img(^.src := "web/rotate_left-24px.svg"))),
        <.div(^.cls := "col-4", <.a(^.onClick ==> deleteImageClicked, <.img(^.src := "web/delete-24px.svg"))),
        <.div(^.cls := "col-4", <.a(^.onClick ==> rotateRightClicked, <.img(^.src := "web/rotate_right-24px.svg"))),
      )
    )
  } else {
    None
  }

  private def deleteImageClicked(event: ReactEventFromHtml): react.Callback =
    $.props.flatMap(icp =>  Callback.future { BackendApi.deleteImage(icp.imageContext).map {
      case Right(g) => icp.imageContext.updateGalleryFn(Some(g))
      case Left(cb) => Callback(false)
    }}
    )

  private def rotateLeftClicked(event: ReactEventFromHtml): react.Callback  = rotate("CW_270")
  private def rotateRightClicked(event: ReactEventFromHtml): react.Callback  = rotate("CW_90")

  private def rotate(direction: String): react.Callback = {
    $.props.flatMap(icp =>  Callback.future { BackendApi.rotateImage(icp.imageContext, direction).map {
      case Right(i) => $.modState(s => s.copy(image = i))
      case Left(_) => Callback(false)
    }}
    )
  }
}
