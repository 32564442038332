package markdixon.name.frontend

import japgolly.scalajs.react.Callback
import japgolly.scalajs.react.extra.router.RouterCtl
import markdixon.name.frontend.facades.JwtDecode
import org.scalajs.dom

import scala.scalajs.js
import scala.scalajs.js.{Date, UndefOr}
import scala.util.{Failure, Success, Try}

class JwtCookie extends js.Object {

  private val cookieName="jwt"

  def readJwtCookie(name: String): Option[Jwt] = {
    val nameEQ = s"$name="
    val ca = dom.document.cookie.split(';')
    ca.map(_.trim)
      .find(c => c.startsWith(nameEQ))
      .flatMap(trimmed => decodeJwt(trimmed.substring(nameEQ.length, trimmed.length)))
  }

  private def decodeJwt(cookie: String): Option[Jwt] = {
    Try(JwtDecode.jwtDecode(cookie).asInstanceOf[Jwt]) match {
      case Success(value) => Some(value)
      case Failure(exception) =>
        println(exception.getMessage)
        clearCookie()
        None
    }
  }

  def redirectToLogin(routerCtl: RouterCtl[AppPage], selectedGallery: Option[String], selectedImage:Option[String]): Callback = {
    clearCookie()
    routerCtl.set(selectedGallery.map(LoginPageWithGallery(_, selectedImage)).getOrElse(LoginPage))
  }

  private def clearCookie() = {
    val d = new Date()
    d.setTime(d.getTime() - (1000 * 60 * 60 * 24))
    dom.window.document.cookie = s"$cookieName=; expires=" + d.toUTCString() + ";SameSite=Lax"
  }

  def checkLoggedIn(): Option[Jwt] = readJwtCookie(cookieName)

}

@js.native
trait Jwt extends js.Object {
  val isAdmin: UndefOr[Boolean] = js.native
  val sub: String = js.native
  val iat: Long = js.native
  val eat: Long = js.native
  val email: String = js.native
}

