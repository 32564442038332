package markdixon.name.frontend.modals

import japgolly.scalajs.react
import japgolly.scalajs.react.vdom.html_<^.{^, _}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}
import markdixon.name.EmailLogin
import markdixon.name.frontend.BackendApi
import markdixon.name.frontend.BackendApi.Error

import scala.scalajs.concurrent.JSExecutionContext.Implicits.queue

object LoginPopup {

  val component = ScalaComponent.builder[LoginPopupProps]("Login Form")
    .initialStateFromProps(p => LoginPopupState(true, false, new EmailLogin("", "", "", p.state)))
    .backend(new LoginPopupOps(_))
    .renderBackend
    .build
}

case class LoginPopupState(formVisible: Boolean, msgVisible: Boolean, emailLogin: EmailLogin)

case class LoginPopupProps(state: String)

class LoginPopupOps($: BackendScope[LoginPopupProps, LoginPopupState]) {

  def render(props: LoginPopupProps, s: LoginPopupState) = {

    <.div(^.cls := "modal fade", ^.tabIndex := -1, ^.id := "loginModal",  ^.role := "dialog", ^.aria.hidden := true, ^.aria.labelledBy := "loginModalLabel",
      <.div(^.cls := "modal-dialog modal-lg", ^.role := "document",
        <.div(^.cls := "modal-content",
          <.div(^.cls := "modal-header",
            <.h5(^.cls := "modal-title", ^.id := s"loginModalLabel", "Enter Login Details"),
            <.button(^.`type` := "button", ^.cls := "close", VdomAttr("data-dismiss") := "modal", ^.aria.label := "Close",
              <.span(^.aria.hidden := true, ^.dangerouslySetInnerHtml := "&times;")
            )
          ),
          <.div(^.cls := "modal-body",
            <.div(^.classSet("show" -> s.formVisible, "hide" -> !s.formVisible),
              <.form(^.id := "loginForm",
                <.div(^.cls := "form-group",
                  <.label(^.`for` := "inputEmail1", "Email address"),
                  <.input(^.`type` := "email", ^.cls := "form-control", ^.id := "inputEmail1", ^.placeholder := "Enter email", ^.required := true, ^.value := s.emailLogin.email, ^.onChange ==> updateEmail)
                ),
                <.div(^.cls := "form-group",
                  <.label(^.`for` := "inputFirstname1", "First Name"),
                  <.input(^.`type` := "text", ^.cls := "form-control", ^.id := "inputFirstname1", ^.placeholder := "Enter first name", ^.required := true, ^.value := s.emailLogin.firstname, ^.onChange ==> updateFirstname),
                  <.label(^.`for` := "inputLastname1", "Last Name"),
                  <.input(^.`type` := "text", ^.cls := "form-control", ^.id := "inputFirstname1", ^.placeholder := "Enter last name", ^.required := true, ^.value := s.emailLogin.lastname, ^.onChange ==> updateLastname)
                ),
              ),
            ),
            <.div(^.classSet("show" -> s.msgVisible, "hide" -> !s.msgVisible),
              "Thanks, please check your email for a login link."
            )
          ),
          <.div(^.cls := "modal-footer",
            <.button(^.`type` := "button", ^.cls := "btn btn-secondary", "Login", ^.onClick ==> doLogin(s))
          )
        )
      )
    )
  }

  def updateEmail(event: ReactEventFromInput): Callback = {
    val newEmail = event.target.value
    $.modState(s => s.copy(emailLogin = s.emailLogin.copy(email = newEmail)))
  }

  def updateFirstname(event: ReactEventFromInput): Callback = {
    val newFirstName = event.target.value
    $.modState(s => s.copy(emailLogin = s.emailLogin.copy(firstname = newFirstName)))
  }

  def updateLastname(event: ReactEventFromInput): Callback = {
    val newLastName = event.target.value
    $.modState(s => s.copy(emailLogin = s.emailLogin.copy(lastname = newLastName)))
  }

  def doLogin(s: LoginPopupState)(event: ReactEventFromInput): react.Callback = {
    Callback.future(BackendApi.postLogin(s.emailLogin).map {
      case Right(_) => $.modState(s => s.copy(formVisible = false, msgVisible = true))
      case Left(Error(msg)) => Callback(false)
    })
  }

}