package markdixon.name

import scala.scalajs.js.annotation.{JSExport, JSExportAll, JSExportTopLevel}

@JSExportAll
case class EmailLogin(email: String, firstname: String, lastname: String, state: String)

@JSExportAll
case class Gallery(id: Option[String], name: String, description: Option[String], images: List[Image], comments: Seq[Comment], public: Boolean) extends Commentable {

  def replaceImage(index: Int, image: Image): Gallery = {
    val newImages = images.updated(index, image)
    this.copy(id, name, description, newImages, comments, public)
  }

  override def getComments: Seq[Comment] = comments
}

trait Commentable {
  def getComments: Seq[Comment]
}

@JSExportTopLevel("Image")
case class Image(id: String, name: Option[String], imageUrl: String, thumbnailUrl: String, comments: Seq[Comment], mimeType: Option[String]) extends Commentable {
  override def getComments: Seq[Comment] = comments
}

@JSExportTopLevel("Comment")
case class Comment(id: String, text: String, firstName: String, lastName: String)


@JSExportTopLevel("User")
case class User(_id: String, firstName: Option[String], lastName: Option[String], email: String, isAdmin: Boolean, isBanned: Boolean)

@JSExportTopLevel("NewComment")
case class NewComment(comment:String)